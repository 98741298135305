import React, { useEffect } from 'react';
import AutoType from './AutoType';

import Links from './Links';

export default function Banner() {
  return (
    <section className="banner">
      <Links placement="top" />
      <div className="banner-border">
        <svg
          className="name"
          width="862"
          height="106"
          viewBox="0 0 862 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1" fill="magenta">
            <path d="M46.9913 105.16C39.9833 105.16 33.5993 104.008 27.8393 101.704C22.0793 99.4 17.1353 96.04 13.0073 91.624C8.87929 87.208 5.6633 81.784 3.3593 75.352C1.1513 68.824 0.0473022 61.384 0.0473022 53.032C0.0473022 44.68 1.29531 37.288 3.79131 30.856C6.38331 24.328 9.8393 18.856 14.1593 14.44C18.4793 10.024 23.5193 6.664 29.2793 4.35999C35.0393 2.05599 41.1833 0.903992 47.7113 0.903992C51.8393 0.903992 55.5353 1.19199 58.7993 1.768C62.1593 2.344 65.0393 3.016 67.4393 3.784C69.9353 4.55199 71.9513 5.31999 73.4873 6.08799C75.0233 6.856 76.0793 7.432 76.6553 7.816L72.6233 19.336C71.7593 18.76 70.5113 18.136 68.8793 17.464C67.2473 16.792 65.3753 16.12 63.2633 15.448C61.2473 14.776 58.9913 14.248 56.4953 13.864C54.0953 13.384 51.6473 13.144 49.1513 13.144C43.8713 13.144 39.1193 14.056 34.8953 15.88C30.6713 17.704 27.0713 20.344 24.0953 23.8C21.1193 27.256 18.8153 31.48 17.1833 36.472C15.6473 41.368 14.8793 46.888 14.8793 53.032C14.8793 58.984 15.5513 64.408 16.8953 69.304C18.3353 74.2 20.4473 78.424 23.2313 81.976C26.0153 85.432 29.4713 88.12 33.5993 90.04C37.8233 91.96 42.7193 92.92 48.2873 92.92C54.7193 92.92 60.0473 92.248 64.2713 90.904C68.4953 89.56 71.6633 88.36 73.7753 87.304L77.3753 98.824C76.7033 99.304 75.5033 99.928 73.7753 100.696C72.0473 101.368 69.8873 102.04 67.2953 102.712C64.7033 103.384 61.6793 103.96 58.2233 104.44C54.7673 104.92 51.0233 105.16 46.9913 105.16Z" />
            <path d="M130.528 105.16C124 105.16 118.336 104.152 113.536 102.136C108.736 100.12 104.8 97.384 101.728 93.928C98.6562 90.376 96.3522 86.2 94.8162 81.4C93.3762 76.6 92.6562 71.416 92.6562 65.848V3.208H106.624V64.264C106.624 74.248 108.832 81.496 113.248 86.008C117.664 90.52 123.424 92.776 130.528 92.776C134.08 92.776 137.296 92.248 140.176 91.192C143.152 90.04 145.696 88.312 147.808 86.008C149.92 83.704 151.552 80.776 152.704 77.224C153.856 73.576 154.432 69.256 154.432 64.264V3.208H168.4V65.848C168.4 71.416 167.632 76.6 166.096 81.4C164.656 86.2 162.352 90.376 159.184 93.928C156.112 97.384 152.176 100.12 147.376 102.136C142.672 104.152 137.056 105.16 130.528 105.16Z" />
            <path d="M241.333 61.24C242.869 63.16 244.789 65.704 247.093 68.872C249.493 71.944 251.941 75.4 254.437 79.24C256.933 82.984 259.381 86.92 261.781 91.048C264.277 95.176 266.389 99.16 268.117 103H252.853C250.933 99.352 248.821 95.656 246.517 91.912C244.309 88.168 242.053 84.616 239.749 81.256C237.445 77.8 235.141 74.584 232.837 71.608C230.629 68.632 228.613 66.04 226.789 63.832C225.541 63.928 224.245 63.976 222.901 63.976C221.653 63.976 220.357 63.976 219.013 63.976H206.485V103H192.517V4.64799C196.453 3.688 200.821 3.064 205.621 2.77599C210.517 2.39199 214.981 2.19999 219.013 2.19999C233.029 2.19999 243.685 4.83999 250.981 10.12C258.373 15.4 262.069 23.272 262.069 33.736C262.069 40.36 260.293 46.024 256.741 50.728C253.285 55.432 248.149 58.936 241.333 61.24ZM220.165 14.44C214.213 14.44 209.653 14.584 206.485 14.872V52.456H216.421C221.221 52.456 225.541 52.216 229.381 51.736C233.221 51.256 236.437 50.344 239.029 49C241.717 47.656 243.781 45.784 245.221 43.384C246.661 40.888 247.381 37.624 247.381 33.592C247.381 29.848 246.661 26.728 245.221 24.232C243.781 21.736 241.813 19.768 239.317 18.328C236.917 16.888 234.037 15.88 230.677 15.304C227.413 14.728 223.909 14.44 220.165 14.44Z" />
            <path d="M349.751 3.208V15.304H318.359V103H304.391V15.304H272.999V3.208H349.751Z" />
            <path d="M364.501 3.208H378.469V103H364.501V3.208Z" />
            <path d="M426.357 93.064C440.949 93.064 448.245 88.072 448.245 78.088C448.245 75.016 447.573 72.424 446.229 70.312C444.981 68.104 443.253 66.232 441.045 64.696C438.837 63.064 436.293 61.672 433.413 60.52C430.629 59.368 427.653 58.216 424.485 57.064C420.837 55.816 417.381 54.424 414.117 52.888C410.853 51.256 408.021 49.384 405.621 47.272C403.221 45.064 401.301 42.472 399.861 39.496C398.517 36.52 397.845 32.92 397.845 28.696C397.845 19.96 400.821 13.144 406.773 8.24799C412.725 3.35199 420.933 0.903992 431.397 0.903992C437.445 0.903992 442.917 1.57599 447.813 2.91999C452.805 4.16799 456.453 5.56 458.757 7.096L454.293 18.472C452.277 17.224 449.253 16.024 445.221 14.872C441.285 13.624 436.677 13 431.397 13C428.709 13 426.213 13.288 423.909 13.864C421.605 14.44 419.589 15.304 417.861 16.456C416.133 17.608 414.741 19.096 413.685 20.92C412.725 22.648 412.245 24.712 412.245 27.112C412.245 29.8 412.773 32.056 413.829 33.88C414.885 35.704 416.373 37.336 418.293 38.776C420.213 40.12 422.421 41.368 424.917 42.52C427.509 43.672 430.341 44.824 433.413 45.976C437.733 47.704 441.669 49.432 445.221 51.16C448.869 52.888 451.989 54.952 454.581 57.352C457.269 59.752 459.333 62.632 460.773 65.992C462.213 69.256 462.933 73.24 462.933 77.944C462.933 86.68 459.717 93.4 453.285 98.104C446.949 102.808 437.973 105.16 426.357 105.16C422.421 105.16 418.773 104.872 415.413 104.296C412.149 103.816 409.221 103.24 406.629 102.568C404.037 101.8 401.781 101.032 399.861 100.264C398.037 99.4 396.597 98.68 395.541 98.104L399.717 86.584C401.925 87.832 405.285 89.224 409.797 90.76C414.309 92.296 419.829 93.064 426.357 93.064Z" />
            <path d="M574.054 53.896H588.022V100.552C586.87 100.936 585.19 101.368 582.982 101.848C580.87 102.328 578.374 102.808 575.494 103.288C572.71 103.768 569.638 104.152 566.278 104.44C562.918 104.824 559.51 105.016 556.054 105.016C549.046 105.016 542.662 103.864 536.902 101.56C531.142 99.256 526.198 95.896 522.07 91.48C517.942 87.064 514.726 81.64 512.422 75.208C510.214 68.776 509.11 61.384 509.11 53.032C509.11 44.68 510.358 37.288 512.854 30.856C515.446 24.328 518.902 18.856 523.222 14.44C527.542 10.024 532.582 6.664 538.342 4.35999C544.198 2.05599 550.438 0.903992 557.062 0.903992C561.574 0.903992 565.558 1.19199 569.014 1.768C572.566 2.344 575.59 3.016 578.086 3.784C580.582 4.55199 582.598 5.31999 584.134 6.08799C585.766 6.856 586.87 7.432 587.446 7.816L582.982 19.48C580.198 17.656 576.502 16.168 571.894 15.016C567.286 13.768 562.582 13.144 557.782 13.144C552.694 13.144 548.038 14.056 543.814 15.88C539.686 17.704 536.134 20.344 533.158 23.8C530.278 27.256 528.022 31.48 526.39 36.472C524.758 41.368 523.942 46.888 523.942 53.032C523.942 58.984 524.614 64.408 525.958 69.304C527.398 74.2 529.51 78.424 532.294 81.976C535.174 85.432 538.678 88.12 542.806 90.04C547.03 91.96 551.974 92.92 557.638 92.92C561.67 92.92 565.078 92.728 567.862 92.344C570.742 91.864 572.806 91.432 574.054 91.048V53.896Z" />
            <path d="M658.708 61.24C660.244 63.16 662.164 65.704 664.468 68.872C666.868 71.944 669.316 75.4 671.812 79.24C674.308 82.984 676.756 86.92 679.156 91.048C681.652 95.176 683.764 99.16 685.492 103H670.228C668.308 99.352 666.196 95.656 663.892 91.912C661.684 88.168 659.428 84.616 657.124 81.256C654.82 77.8 652.516 74.584 650.212 71.608C648.004 68.632 645.988 66.04 644.164 63.832C642.916 63.928 641.62 63.976 640.276 63.976C639.028 63.976 637.732 63.976 636.388 63.976H623.86V103H609.892V4.64799C613.828 3.688 618.196 3.064 622.996 2.77599C627.892 2.39199 632.356 2.19999 636.388 2.19999C650.404 2.19999 661.06 4.83999 668.356 10.12C675.748 15.4 679.444 23.272 679.444 33.736C679.444 40.36 677.668 46.024 674.116 50.728C670.66 55.432 665.524 58.936 658.708 61.24ZM637.54 14.44C631.588 14.44 627.028 14.584 623.86 14.872V52.456H633.796C638.596 52.456 642.916 52.216 646.756 51.736C650.596 51.256 653.812 50.344 656.404 49C659.092 47.656 661.156 45.784 662.596 43.384C664.036 40.888 664.756 37.624 664.756 33.592C664.756 29.848 664.036 26.728 662.596 24.232C661.156 21.736 659.188 19.768 656.692 18.328C654.292 16.888 651.412 15.88 648.052 15.304C644.788 14.728 641.284 14.44 637.54 14.44Z" />
            <path d="M771.204 103C769.572 98.68 768.036 94.456 766.596 90.328C765.156 86.104 763.668 81.832 762.132 77.512H716.916L707.844 103H693.3C697.14 92.44 700.74 82.696 704.1 73.768C707.46 64.744 710.724 56.2 713.892 48.136C717.156 40.072 720.372 32.392 723.54 25.096C726.708 17.704 730.02 10.408 733.476 3.208H746.292C749.748 10.408 753.06 17.704 756.228 25.096C759.396 32.392 762.564 40.072 765.732 48.136C768.996 56.2 772.308 64.744 775.668 73.768C779.028 82.696 782.628 92.44 786.468 103H771.204ZM758.1 65.992C755.028 57.64 751.956 49.576 748.884 41.8C745.908 33.928 742.788 26.392 739.524 19.192C736.164 26.392 732.948 33.928 729.876 41.8C726.9 49.576 723.924 57.64 720.948 65.992H758.1Z" />
            <path d="M812.386 103V62.968C805.762 52.696 799.522 42.568 793.666 32.584C787.81 22.6 782.29 12.808 777.106 3.208H793.522C797.17 11.08 801.25 19.096 805.762 27.256C810.274 35.32 814.93 43.096 819.73 50.584C824.434 43.096 829.042 35.32 833.554 27.256C838.066 19.096 842.194 11.08 845.938 3.208H861.49C856.306 12.808 850.786 22.6 844.93 32.584C839.074 42.472 832.882 52.504 826.354 62.68V103H812.386Z" />
          </mask>
          <path
            d="M46.9913 105.16C39.9833 105.16 33.5993 104.008 27.8393 101.704C22.0793 99.4 17.1353 96.04 13.0073 91.624C8.87929 87.208 5.6633 81.784 3.3593 75.352C1.1513 68.824 0.0473022 61.384 0.0473022 53.032C0.0473022 44.68 1.29531 37.288 3.79131 30.856C6.38331 24.328 9.8393 18.856 14.1593 14.44C18.4793 10.024 23.5193 6.664 29.2793 4.35999C35.0393 2.05599 41.1833 0.903992 47.7113 0.903992C51.8393 0.903992 55.5353 1.19199 58.7993 1.768C62.1593 2.344 65.0393 3.016 67.4393 3.784C69.9353 4.55199 71.9513 5.31999 73.4873 6.08799C75.0233 6.856 76.0793 7.432 76.6553 7.816L72.6233 19.336C71.7593 18.76 70.5113 18.136 68.8793 17.464C67.2473 16.792 65.3753 16.12 63.2633 15.448C61.2473 14.776 58.9913 14.248 56.4953 13.864C54.0953 13.384 51.6473 13.144 49.1513 13.144C43.8713 13.144 39.1193 14.056 34.8953 15.88C30.6713 17.704 27.0713 20.344 24.0953 23.8C21.1193 27.256 18.8153 31.48 17.1833 36.472C15.6473 41.368 14.8793 46.888 14.8793 53.032C14.8793 58.984 15.5513 64.408 16.8953 69.304C18.3353 74.2 20.4473 78.424 23.2313 81.976C26.0153 85.432 29.4713 88.12 33.5993 90.04C37.8233 91.96 42.7193 92.92 48.2873 92.92C54.7193 92.92 60.0473 92.248 64.2713 90.904C68.4953 89.56 71.6633 88.36 73.7753 87.304L77.3753 98.824C76.7033 99.304 75.5033 99.928 73.7753 100.696C72.0473 101.368 69.8873 102.04 67.2953 102.712C64.7033 103.384 61.6793 103.96 58.2233 104.44C54.7673 104.92 51.0233 105.16 46.9913 105.16Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M130.528 105.16C124 105.16 118.336 104.152 113.536 102.136C108.736 100.12 104.8 97.384 101.728 93.928C98.6562 90.376 96.3522 86.2 94.8162 81.4C93.3762 76.6 92.6562 71.416 92.6562 65.848V3.208H106.624V64.264C106.624 74.248 108.832 81.496 113.248 86.008C117.664 90.52 123.424 92.776 130.528 92.776C134.08 92.776 137.296 92.248 140.176 91.192C143.152 90.04 145.696 88.312 147.808 86.008C149.92 83.704 151.552 80.776 152.704 77.224C153.856 73.576 154.432 69.256 154.432 64.264V3.208H168.4V65.848C168.4 71.416 167.632 76.6 166.096 81.4C164.656 86.2 162.352 90.376 159.184 93.928C156.112 97.384 152.176 100.12 147.376 102.136C142.672 104.152 137.056 105.16 130.528 105.16Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M241.333 61.24C242.869 63.16 244.789 65.704 247.093 68.872C249.493 71.944 251.941 75.4 254.437 79.24C256.933 82.984 259.381 86.92 261.781 91.048C264.277 95.176 266.389 99.16 268.117 103H252.853C250.933 99.352 248.821 95.656 246.517 91.912C244.309 88.168 242.053 84.616 239.749 81.256C237.445 77.8 235.141 74.584 232.837 71.608C230.629 68.632 228.613 66.04 226.789 63.832C225.541 63.928 224.245 63.976 222.901 63.976C221.653 63.976 220.357 63.976 219.013 63.976H206.485V103H192.517V4.64799C196.453 3.688 200.821 3.064 205.621 2.77599C210.517 2.39199 214.981 2.19999 219.013 2.19999C233.029 2.19999 243.685 4.83999 250.981 10.12C258.373 15.4 262.069 23.272 262.069 33.736C262.069 40.36 260.293 46.024 256.741 50.728C253.285 55.432 248.149 58.936 241.333 61.24ZM220.165 14.44C214.213 14.44 209.653 14.584 206.485 14.872V52.456H216.421C221.221 52.456 225.541 52.216 229.381 51.736C233.221 51.256 236.437 50.344 239.029 49C241.717 47.656 243.781 45.784 245.221 43.384C246.661 40.888 247.381 37.624 247.381 33.592C247.381 29.848 246.661 26.728 245.221 24.232C243.781 21.736 241.813 19.768 239.317 18.328C236.917 16.888 234.037 15.88 230.677 15.304C227.413 14.728 223.909 14.44 220.165 14.44Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M349.751 3.208V15.304H318.359V103H304.391V15.304H272.999V3.208H349.751Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M364.501 3.208H378.469V103H364.501V3.208Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M426.357 93.064C440.949 93.064 448.245 88.072 448.245 78.088C448.245 75.016 447.573 72.424 446.229 70.312C444.981 68.104 443.253 66.232 441.045 64.696C438.837 63.064 436.293 61.672 433.413 60.52C430.629 59.368 427.653 58.216 424.485 57.064C420.837 55.816 417.381 54.424 414.117 52.888C410.853 51.256 408.021 49.384 405.621 47.272C403.221 45.064 401.301 42.472 399.861 39.496C398.517 36.52 397.845 32.92 397.845 28.696C397.845 19.96 400.821 13.144 406.773 8.24799C412.725 3.35199 420.933 0.903992 431.397 0.903992C437.445 0.903992 442.917 1.57599 447.813 2.91999C452.805 4.16799 456.453 5.56 458.757 7.096L454.293 18.472C452.277 17.224 449.253 16.024 445.221 14.872C441.285 13.624 436.677 13 431.397 13C428.709 13 426.213 13.288 423.909 13.864C421.605 14.44 419.589 15.304 417.861 16.456C416.133 17.608 414.741 19.096 413.685 20.92C412.725 22.648 412.245 24.712 412.245 27.112C412.245 29.8 412.773 32.056 413.829 33.88C414.885 35.704 416.373 37.336 418.293 38.776C420.213 40.12 422.421 41.368 424.917 42.52C427.509 43.672 430.341 44.824 433.413 45.976C437.733 47.704 441.669 49.432 445.221 51.16C448.869 52.888 451.989 54.952 454.581 57.352C457.269 59.752 459.333 62.632 460.773 65.992C462.213 69.256 462.933 73.24 462.933 77.944C462.933 86.68 459.717 93.4 453.285 98.104C446.949 102.808 437.973 105.16 426.357 105.16C422.421 105.16 418.773 104.872 415.413 104.296C412.149 103.816 409.221 103.24 406.629 102.568C404.037 101.8 401.781 101.032 399.861 100.264C398.037 99.4 396.597 98.68 395.541 98.104L399.717 86.584C401.925 87.832 405.285 89.224 409.797 90.76C414.309 92.296 419.829 93.064 426.357 93.064Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M574.054 53.896H588.022V100.552C586.87 100.936 585.19 101.368 582.982 101.848C580.87 102.328 578.374 102.808 575.494 103.288C572.71 103.768 569.638 104.152 566.278 104.44C562.918 104.824 559.51 105.016 556.054 105.016C549.046 105.016 542.662 103.864 536.902 101.56C531.142 99.256 526.198 95.896 522.07 91.48C517.942 87.064 514.726 81.64 512.422 75.208C510.214 68.776 509.11 61.384 509.11 53.032C509.11 44.68 510.358 37.288 512.854 30.856C515.446 24.328 518.902 18.856 523.222 14.44C527.542 10.024 532.582 6.664 538.342 4.35999C544.198 2.05599 550.438 0.903992 557.062 0.903992C561.574 0.903992 565.558 1.19199 569.014 1.768C572.566 2.344 575.59 3.016 578.086 3.784C580.582 4.55199 582.598 5.31999 584.134 6.08799C585.766 6.856 586.87 7.432 587.446 7.816L582.982 19.48C580.198 17.656 576.502 16.168 571.894 15.016C567.286 13.768 562.582 13.144 557.782 13.144C552.694 13.144 548.038 14.056 543.814 15.88C539.686 17.704 536.134 20.344 533.158 23.8C530.278 27.256 528.022 31.48 526.39 36.472C524.758 41.368 523.942 46.888 523.942 53.032C523.942 58.984 524.614 64.408 525.958 69.304C527.398 74.2 529.51 78.424 532.294 81.976C535.174 85.432 538.678 88.12 542.806 90.04C547.03 91.96 551.974 92.92 557.638 92.92C561.67 92.92 565.078 92.728 567.862 92.344C570.742 91.864 572.806 91.432 574.054 91.048V53.896Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M658.708 61.24C660.244 63.16 662.164 65.704 664.468 68.872C666.868 71.944 669.316 75.4 671.812 79.24C674.308 82.984 676.756 86.92 679.156 91.048C681.652 95.176 683.764 99.16 685.492 103H670.228C668.308 99.352 666.196 95.656 663.892 91.912C661.684 88.168 659.428 84.616 657.124 81.256C654.82 77.8 652.516 74.584 650.212 71.608C648.004 68.632 645.988 66.04 644.164 63.832C642.916 63.928 641.62 63.976 640.276 63.976C639.028 63.976 637.732 63.976 636.388 63.976H623.86V103H609.892V4.64799C613.828 3.688 618.196 3.064 622.996 2.77599C627.892 2.39199 632.356 2.19999 636.388 2.19999C650.404 2.19999 661.06 4.83999 668.356 10.12C675.748 15.4 679.444 23.272 679.444 33.736C679.444 40.36 677.668 46.024 674.116 50.728C670.66 55.432 665.524 58.936 658.708 61.24ZM637.54 14.44C631.588 14.44 627.028 14.584 623.86 14.872V52.456H633.796C638.596 52.456 642.916 52.216 646.756 51.736C650.596 51.256 653.812 50.344 656.404 49C659.092 47.656 661.156 45.784 662.596 43.384C664.036 40.888 664.756 37.624 664.756 33.592C664.756 29.848 664.036 26.728 662.596 24.232C661.156 21.736 659.188 19.768 656.692 18.328C654.292 16.888 651.412 15.88 648.052 15.304C644.788 14.728 641.284 14.44 637.54 14.44Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M771.204 103C769.572 98.68 768.036 94.456 766.596 90.328C765.156 86.104 763.668 81.832 762.132 77.512H716.916L707.844 103H693.3C697.14 92.44 700.74 82.696 704.1 73.768C707.46 64.744 710.724 56.2 713.892 48.136C717.156 40.072 720.372 32.392 723.54 25.096C726.708 17.704 730.02 10.408 733.476 3.208H746.292C749.748 10.408 753.06 17.704 756.228 25.096C759.396 32.392 762.564 40.072 765.732 48.136C768.996 56.2 772.308 64.744 775.668 73.768C779.028 82.696 782.628 92.44 786.468 103H771.204ZM758.1 65.992C755.028 57.64 751.956 49.576 748.884 41.8C745.908 33.928 742.788 26.392 739.524 19.192C736.164 26.392 732.948 33.928 729.876 41.8C726.9 49.576 723.924 57.64 720.948 65.992H758.1Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
          <path
            d="M812.386 103V62.968C805.762 52.696 799.522 42.568 793.666 32.584C787.81 22.6 782.29 12.808 777.106 3.208H793.522C797.17 11.08 801.25 19.096 805.762 27.256C810.274 35.32 814.93 43.096 819.73 50.584C824.434 43.096 829.042 35.32 833.554 27.256C838.066 19.096 842.194 11.08 845.938 3.208H861.49C856.306 12.808 850.786 22.6 844.93 32.584C839.074 42.472 832.882 52.504 826.354 62.68V103H812.386Z"
            stroke="white"
            strokeWidth="5"
            mask="url(#path-1-inside-1)"
          />
        </svg>

        <AutoType text="Web Developer" />
      </div>
    </section>
  );
}
